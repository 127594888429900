<template>
  <div ref="searchHeader"
       class="search-header">
    <a-form :model="searchForm"
            layout="inline"
            :colon="false">

      <a-form-item label="专栏名称">
        <a-input-search v-model:value="searchForm.name"
                        placeholder="请输入专栏名称"
                        style="width: 280px"
                        @keydown.enter="onSearchSubmit"
                        v-debounce="onSearchSubmit" />
      </a-form-item>
    </a-form>
    <router-link :to="{path: '/marketingUtil/liveColumnCreate'}">
      <a-button type="primary">
        <svg-icon type="icontianjia1" />新建专栏
      </a-button>
    </router-link>
  </div>

  <div ref="tableRef">
    <a-table row-key="id"
             :columns="columns"
             :data-source="liveColumns"
             :scroll="{ y: 'calc(100vh - 383px)' }"
             :pagination="pagination"
             :loading="tableLoading"
             @change="onPaginationChange">

      <template #name="{ record }">
        <a-image :src="record.cover"
                 width="160px"
                 height="90px" />
        <p class="mt-8 line-clamp-2"
           style="max-width: 160px;">{{ record.name }}</p>
      </template>

      <template #action="{record}">
        <a-space :size="16">
          <router-link :to="{name: 'liveColumn_statistic', query: {setId: record.id}}">
            <a-button type="link">详情</a-button>
          </router-link>

          <a-button type="link"
                    @click="shareLiveRoom(record)">分享</a-button>

          <router-link :to="{name: 'liveColumn_create', query: {isEdit: true, setId: record.id }}">
            <a-button type="link">编辑</a-button>
          </router-link>

          <a-button type="link"
                    @click='openDeleteDialog(record)'>
            删除
          </a-button>
        </a-space>

      </template>
    </a-table>
  </div>

  <delete-modal v-model:visible="deleteModalVisible"
                title="删除直播专栏"
                :delete-item="deleteItem"
                @delete="deleteLiveRoom" />

  <share-modal v-model:visible="shareModalVisible"
               title="分享直播专栏"
               :footer="null"
               width="600px"
               :item="shareLive" />
</template>

<script>
import { defineComponent, ref, reactive } from "vue";
import { message, Space, Image } from "ant-design-vue";
import _ from "lodash";
import router from "@/router";

import SvgIcon from "@/components/SvgIcon";
import DeleteModal from "@/components/DeleteModal";
import ShareModal from "@/components/ShareLinkModal";

import liveApi from "@/service/api/live";
import usePaginationQuery from "@/shared/composables/usePaginationQuery";

export default defineComponent({
  name: "LiveOpenColumnIndex",

  components: {
    ASpace: Space,
    AImage: Image,

    SvgIcon,
    DeleteModal,
    ShareModal,
  },

  setup() {
    // 搜索直播
    const searchForm = reactive({
      name: "",
    });

    // 专栏列表
    const columns = [
      {
        title: "专栏",
        dataIndex: "name",
        slots: {
          customRender: "name",
        },
      },
      {
        title: "更新期数",
        dataIndex: "liveNumber",
      },
      {
        title: "观看人数",
        dataIndex: "viewerNumber",
      },
      {
        title: "操作",
        key: "action",
        slots: {
          customRender: "action",
        },
      },
    ];

    const {
      rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    } = usePaginationQuery(router, searchForm, liveApi.searchLiveRoomSet);

    // 删除直播
    const deleteModalVisible = ref(false);

    const deleteItem = reactive({});

    const openDeleteDialog = (record) => {
      _.assign(deleteItem, record);
      deleteModalVisible.value = true;
    };

    const deleteLiveRoom = async () => {
      await liveApi
        .deleteLiveRoomSet({
          id: deleteItem.id,
        })
        .catch(() => {
          message.error("删除失败，请重试");
          return;
        });

      message.success("删除成功");
      fetchPaginationData();
      deleteModalVisible.value = false;
    };

    // 分享直播
    const shareModalVisible = ref(false);
    const shareLive = reactive({
      title: "",
      link: "",
    });

    const shareLiveRoom = function (record) {
      shareModalVisible.value = true;

      const { corpId, externalId } = record;
      shareLive.title = record.name;
      shareLive.link = `https://${window.location.host}/customer-h5/live/column?externalId=${externalId}&corpId=${corpId}`;
    };

    return {
      searchForm,

      columns,
      liveColumns: rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,

      deleteModalVisible,
      deleteItem,
      openDeleteDialog,
      deleteLiveRoom,

      shareModalVisible,
      shareLive,
      shareLiveRoom,
    };
  },
});
</script>

<style lang='less' scoped>
.display-btn {
  .edit-btn {
    display: unset;
  }
}
</style>
