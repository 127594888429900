<template>
  <div v-if="isLoading"
       class="absolute top-0 left-0 flex justify-center items-center w-full h-full bg-color-f z-10">
    <a-spin />
  </div>

  <template v-else>
    <a-tabs v-model:activeKey="activeComponent"
            @change="handleChangeTab">
      <a-tab-pane key="class"
                  tab="直播课"></a-tab-pane>
      <a-tab-pane key="column"
                  tab="专栏"></a-tab-pane>
    </a-tabs>

    <keep-alive>
      <component :is="Components[activeComponent]"></component>
    </keep-alive>
  </template>
</template>

<script>
import _ from "lodash";
import { defineComponent, ref } from "vue";
import { Tabs } from "ant-design-vue";

import LiveClassIndex from "@/views/liveOpenClass/liveClass/LiveClassIndex";
import LiveColumnIndex from "@/views/liveOpenClass/liveColumn/LiveColumnIndex";

import liveApi from "@/service/api/live";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "LiveOpenClassIndex",
  props: [],
  emits: [],
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
  },
  setup() {
    const route = new useRoute();
    const router = new useRouter();
    const isLoading = ref(true);

    const Components = {
      class: LiveClassIndex,
      column: LiveColumnIndex,
    };

    const activeComponent = ref("class");

    if (route.query.active) {
      activeComponent.value = route.query.active;
    }

    const handleChangeTab = () => {
      const query = _.assign({}, route.query, { page: 1, size: 20 });
      router.push({
        name: route.name,
        query: query,
      });
    };

    // 判断是否已开通直播服务

    const judgeIsOpen = async () => {
      const res = await liveApi.isOpenLiveAccount();

      if (!res) {
        router.push({
          name: "liveOpenClass_setting",
        });
        return;
      }
      isLoading.value = false;
    };
    judgeIsOpen();

    return {
      isLoading,
      Components,
      activeComponent,
      handleChangeTab,
    };
  },
});
</script>
<style lang='less' scoped>
</style>